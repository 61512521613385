// Packages
import React from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteIcon, KiteTooltip } from '@kite/react-kite';

// Utils

// Types

// Styles
import './AddPortPill.scss';

export interface IAddPortPillProps {
  /** Determines if location is okay for Power/Port/Wifi */
  isOkay: boolean;
  /** Name for the Pill */
  name?: string;
  /** Value for the Pill in case of Power/Port */
  value?: number;
  /** Unit for the Pill in case of Power/Port (Watts/Ports) */
  unit?: string;
}

/** A pill displaying whether a location is POWER Ok/Add Switch, PORTS Ok/Add Port, WIFI Ok/Add Wifi */
const AddPortPill = ({
  isOkay = true,
  name = '',
  value = 0,
  unit = '',
}: IAddPortPillProps) => {
  const iconName = isOkay ? 'checkmark' : 'caution-circle';
  // Warning message for Power Pill
  const warningMessage = (
    <p>
      <strong className="pill-text-color--warning">Warning:</strong> {'>'}= 80%
      of power consumed and {'<'} 300 Watts are available. Please add more
      switches.
    </p>
  );

  return (
    <div className="pill-container">
      {unit && (
        <h4
          className={classNames({
            'pill-text-color': true,
            'pill-text-color--negative': !isOkay,
          })}
        >
          {`${value > 0 ? '+' : ''}${value
            .toFixed(2)
            .replace(/\.00$/, '')} ${unit}`}
          {unit === 'Watts' && !isOkay && (
            <KiteTooltip children={warningMessage} />
          )}
        </h4>
      )}
      <div
        className={classNames({
          'addport-pill': true,
          'addport-pill--non-addport': !isOkay,
          'addport-pill--non-drt': !isOkay && name.match(/DRT/g)?.length,
          'addport-pill--total-power': name === "Total Power Req'd",
          'addport-pill--non-mnequestion': name === 'ENE Questions Req’d',
          'addport-pill--select-solution': name.match(/(Solution|&)/g)?.length,
        })}
      >
        <KiteIcon name={iconName} />
        {name}
      </div>
    </div>
  );
};

export default AddPortPill;
